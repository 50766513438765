<template>
  <div>
    <div class="row items-center justify-between" v-if="!isCalculator">
      <h5 class="q-my-none">
        {{ $t('Pick up points') }}
      </h5>

      <q-btn
          color="transparent"
          :text-color="$q.dark.isActive ? 'white' : 'dark'"
          icon="close"
          no-caps
          unelevated
          @click="handleBack"
      />
    </div>

    <div style="min-height: 70vh">
      <form-builder :schema="schema" v-if="!isCalculator"/>

      <div class="row">
        <div class="col-12 col-md-6">
          <form-builder :schema="searchSchema"/>

          <div v-if="note" class="test-subtitle1 q-px-lg q-py-xs text-yellow-9">
            {{ $t('Recipient address') }}
          </div>

          <div v-if="note" class="text-subtitle1 q-mb-sm q-px-md rounded">
            <q-chip
                v-for="(word, i) in words"
                :key="`${word}:${i}`"
                class="q-mr-sm"
                :class="getChipsClass(word)"
                @click="handleWord(word)"
                clickable
                square
            >
              {{ word }}
            </q-chip>
          </div>

          <div v-if="servicePoints.length > 0 && !servicePointsLoading">
            <q-scroll-area style="height: 450px;">
              <q-list bordered separator>
                <q-item
                    v-for="service of servicePoints"
                    :key="service.id"
                    v-ripple
                    clickable
                    @click="handleMarkerSelect(service)"
                >
                  <q-item-section thumbnail class="q-px-md">
                    <img
                        v-if="logo || (service._embedded.deliveryService && service._embedded.deliveryService.logo)"
                        :src="(logo || (service._embedded.deliveryService && service._embedded.deliveryService.logo))"
                        style="width: 50px; height: 50px; object-fit: contain;"
                    />

                    <div v-else-if="service._embedded.deliveryService">
                      {{ service._embedded.deliveryService.name }}
                    </div>
                  </q-item-section>

                  <q-item-section>
                    <h5 class="q-my-none text-subtitle1">
                      {{ service.name }}
                    </h5>

                    <p class="q-my-none">
                      {{ service.rawAddress }}
                    </p>

                    <small>{{ service.rawTimetable }}</small>

                    <!-- Dynamically bind the button properties based on whether the current service point is selected -->
                    <q-btn
                        :color="isSelected(service) ? 'green' : 'light-blue-9'"
                        :icon="isSelected(service) ? 'mdi-checkbox-marked-circle' : ''"
                        text-color="white"
                        size="sm"
                        style="width: 150px"
                        :label="isSelected(service) ? $t('Selected') : $t('Choose')"
                        no-caps
                        unelevated
                        @click="handleSubmit(service)"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-scroll-area>
          </div>

          <div
              v-else-if="servicePointsLoading"
              class="row items-center justify-center full-height"
              style="max-height: 200px;"
          >
            <q-spinner color="light-blue-9" size="3rem" class="q-mr-md"/>
          </div>

          <div
              v-else
              class="row fit items-center justify-center"
              style="max-height: 200px;"
          >
            {{ $t('No pick up points were found!') }}
          </div>
        </div>

        <div class="col-12 col-md-6">
          <l-map
              ref="map"
              style="min-height: 300px;"
              :zoom="zoom"
              :center="center || defCenter"
              :min-zoom="3"
              :max-zoom="19"
              @ready="onLeafletReady"
          >
            <template v-if="leafletReady">
              <l-tile-layer :url="url"/>
              <l-layer-group ref="features">
                <l-popup>
                  <div class="custom-popup">
                    <div class="row full-width justify-between">
                      <h6 class="q-mx-auto q-my-none">{{ currentPoint.name }}</h6>
                    </div>

                    <p class="text-caption">{{ currentPoint.rawAddress }}</p>
                    <small class="q-my-xs block">{{ currentPoint.rawTimetable }}</small>

                    <q-btn
                        :color="isSelected(currentPoint) ? 'green' : 'light-blue-9'"
                        :icon="isSelected(currentPoint) ? 'mdi-checkbox-marked-circle' : ''"
                        text-color="white"
                        size="sm"
                        :label="isSelected(currentPoint) ? $t('Selected') : $t('Choose')"
                        no-caps
                        unelevated
                        @click="handleSubmit(currentPoint)"
                    />
                  </div>
                </l-popup>
              </l-layer-group>

              <l-marker
                  v-for="point of servicePointsWithGeo"
                  :key="point.id"
                  :lat-lng="createGeo(point.geo)"
                  @click="handleMarkerSelect(point)"
              >
                <l-icon :icon-size="getMarkerSize(createGeo(point.geo))" :icon-url="createIcon(point)"/>
              </l-marker>
            </template>
          </l-map>
        </div>
      </div>
    </div>

    <div class="row justify-end items-center q-pa-sm border-top" v-if="!isCalculator">
      <div class="q-mr-sm">
        <q-btn color="dark" text-color="white" size="sm" :label="$t('Close')" no-caps unelevated @click="handleBack"/>
      </div>

      <q-btn
          color="light-blue-9"
          text-color="white"
          size="sm"
          :label="$t('Save')"
          no-caps
          unelevated
          @click="handleSubmit(currentPoint)"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
// import MarkerCluster from './MarkerCluster'
import { LMap, LTileLayer, LMarker, LPopup, LIcon, LLayerGroup } from '@vue-leaflet/vue-leaflet/src/lib'

// Configs
import PinsConfig from '../../config/PinsConfig'

// Helpers
import { createGeo } from '../../helpers/request-helpers'

export default {
  name: 'ServicePointsMap',
  emits: ['submit', 'close'],
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    'l-popup': LPopup,
    'l-icon': LIcon,
    'l-layer-group': LLayerGroup
    // MarkerCluster
  },
  props: {
    note: {
      type: String,
      default () {
        return ''
      }
    },
    isCalculator: {
      type: Boolean,
      default () {
        return false
      }
    },
    sender: {
      type: Object,
      default () {
        return null
      }
    },
    locality: {
      type: Object,
      default () {
        return null
      }
    },
    servicePoint: {
      type: Object,
      default () {
        return null
      }
    },
    rate: {
      type: Object,
      default () {
        return null
      }
    },
    outSideFilters: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      defCenter: [43.204666, 27.910543],
      pinTypes: PinsConfig,
      loadedRates: [],
      loadedLocalities: [],
      loadedSenders: [],
      currentPoint: {},
      zoom: 13,
      center: [43.204666, 27.910543],
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      model: {
        sender: null,
        rate: null,
        locality: null,
        search: null
      },
      marker: [43.204666, 27.910543],
      logo: null,
      leafletReady: false,
      leafletObject: null
    }
  },
  computed: {
    ...mapGetters([
      'servicePoints',
      'rates',
      'ratesPage',
      'ratesTotalPages',
      'localities',
      'totalLocalitiesNumber',
      'localitiesPage',
      'localitiesTotalPages',
      'servicePointsLoading',
      'deliveryService',
      'deliveryRequest',
      'appOptions',
      'senders',
      'sendersTotalPages',
      'sendersPage'
    ]),
    words () {
      return this.note.split(' ').map(x => x.replace(',', ''))
    },
    servicePointsWithGeo () {
      return this.servicePoints.filter(x => {
        return x.geo && x.geo.length > 0
      })
    },
    searchSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Search'),
                field: 'search',
                wrapperStyleClasses: 'q-pa-xs',
                value: this.model.search,
                debounce: 300,
                onInput: (search) => {
                  this.model.search = search

                  const query = {
                    search,
                    per_page: -1,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.model.locality) {
                    query.filter.push({ type: 'eq', field: 'locality', value: this.model.locality.id })
                  }

                  if (this.model.rate && this.model.rate._embedded.deliveryService) {
                    const filter = this.createDeliveryServiceFilter(this.model.rate._embedded.deliveryService)
                    query.filter.push(filter)
                  }

                  if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
                    const fix = query.search[query.search.length - 1] === ':'
                        ? '*'
                        : ':*'

                    query.search = query.search.trim() + fix
                  } else if (query.search.includes('%')) {
                    query.search = query.search.trim()
                  }

                  this.loadServicePoints(query)
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Location'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'locality',
                value: this.model.locality,
                required: true,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '') + (row._embedded && row._embedded.area.name ? ' (' + row._embedded.area.name + ')' : '')
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: (value) => {
                  this.model.locality = value

                  if (value.geo) {
                    this.center = this.createGeo(value.geo)
                  } else {
                    this.addWarningNotification('Locality don\'t have geo!')
                  }

                  this.setServicePoints([])
                  this.setServicePointsPage(1)
                  this.setServicePointsTotalPages(1)
                  this.setTotalServicePointsNumber(0)
                  this.currentPoint = {}

                  if (this.model.rate) {
                    const query = {
                      per_page: -1,
                      filter: [
                        { type: 'eq', field: 'state', value: 'active' },
                        { type: 'eq', field: 'locality', value: this.model.locality.id }
                      ]
                    }

                    if (this.model.search) {
                      query.search = this.model.search
                    }

                    if (this.model.rate._embedded.deliveryService) {
                      const filter = this.createDeliveryServiceFilter(this.model.rate._embedded.deliveryService)
                      query.filter.push(filter)
                    }

                    this.loadServicePoints(query)
                  }
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'sender',
                value: this.model.sender,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.owner) {
                    query.filter.push({
                      type: 'eq',
                      field: 'owner',
                      value: this.owner.id
                    })
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (value) => {
                  this.setServicePoints([])
                  this.setServicePointsPage(1)
                  this.setServicePointsTotalPages(1)
                  this.setTotalServicePointsNumber(0)
                  this.model.sender = value
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Shipping rate'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'rate',
                value: this.model.rate,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      ...this.outSideFilters.filter(x => x.value || (x.values && x.values.length > 0)),
                      { type: 'eq', field: 'state', value: 'active' }
                    ],
                    'order-by': [
                      { type: 'field', field: 'deliveryService', direction: 'desc' },
                      { type: 'field', field: 'name', direction: 'desc' }
                    ]
                  }

                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (value) => {
                  if (!value) {
                    return
                  }

                  this.model.rate = value

                  const query = {
                    per_page: -1,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'eq', field: 'deliveryService', value: value._embedded.deliveryService.id }
                    ]
                  }

                  if (this.model.locality) {
                    query.filter.push({ type: 'eq', field: 'locality', value: this.model.locality.id })
                  }

                  this.logo = value._embedded.deliveryService.logo
                  this.loadServicePoints(query)
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    rate: {
      handler () {
        this.initLoad()
      },
      deep: true
    },
  },
  mounted () {
    this.initLoad()

    if (this.servicePoint) {
      this.handleMarkerSelect(this.servicePoint)
    }
  },
  updated () {
    if (this.$refs.map && this.$refs.map.mapObject) {
      this.$refs.map.mapObject._onResize()
    }
  },
  methods: {
    ...mapActions([
      'loadServicePoints'
    ]),
    ...mapMutations([
      'setServicePoints',
      'setServicePointsPage',
      'setServicePointsTotalPages',
      'setTotalServicePointsNumber',
      'addWarningNotification'
    ]),
    initLoad () {
      if (this.sender) {
        this.model.sender = this.sender
      }

      if (this.locality) {
        this.center = this.createGeo(this.locality.geo)
        this.model.locality = this.locality
      }

      if (this.rate) {
        this.model.rate = this.rate

        if (this.model.locality) {
          const query = {
            per_page: -1,
            filter: [
              { type: 'eq', field: 'state', value: 'active' },
              { type: 'eq', field: 'locality', value: this.model.locality.id }
            ]
          }

          let deliveryService
          if (this.rate?._embedded?.deliveryService) {
            deliveryService = this.rate?._embedded?.deliveryService
          }

          if (!deliveryService && this.deliveryRequest?._embedded?.deliveryService) {
            deliveryService = this.deliveryRequest?._embedded?.deliveryService
          }

          if (deliveryService) {
            const filter = this.createDeliveryServiceFilter(deliveryService)
            query.filter.push(filter)

            if (deliveryService.id) {
              this.logo = deliveryService.logo
            } else {
              const id = this.extractIDFromLink(deliveryService)
              const hasLoadedService = this.deliveryService && this.deliveryService.id === Number(id)

              if (hasLoadedService) {
                this.logo = this.deliveryService.logo
              } else {
                this.$service.deliveryService.get(id)
                    .then(item => {
                      this.logo = item.logo
                    })
              }
            }
          }

          this.loadServicePoints(query)
        }
      }
    },
    async onLeafletReady () {
      await this.$nextTick()
      this.leafletObject = this.$refs.map.leafletObject
      this.leafletReady = true
    },
    getChipsClass (word) {
      if (word === this.model.search) {
        return 'bg-dark text-white'
      }

      return this.$q.dark.isActive
          ? 'bg-grey-9'
          : ''
    },
    handleWord (search) {
      this.model.search = search

      const query = {
        search,
        per_page: -1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      if (this.model.locality) {
        query.filter.push({ type: 'eq', field: 'locality', value: this.model.locality.id })
      }

      if (this.model.rate && this.model.rate._embedded.deliveryService) {
        const filter = this.createDeliveryServiceFilter(this.model.rate._embedded.deliveryService)
        query.filter.push(filter)
      }

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        const fix = query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'

        query.search = query.search.trim() + fix
      } else if (query.search.includes('%')) {
        query.search = query.search.trim()
      }

      this.loadServicePoints(query)
    },
    createIcon (point) {
      const pin = this.pinTypes[(point._embedded.deliveryService || {}).name] || this.pinTypes.default

      return this.isChoosed(this.createGeo(point.geo))
          ? this.pinTypes.selectedPin
          : pin
    },
    createDeliveryServiceFilter (deliveryService) {
      if (deliveryService.id) {
        return { type: 'eq', field: 'deliveryService', value: deliveryService.id }
      }

      const value = this.extractIDFromLink(deliveryService)

      return { type: 'eq', field: 'deliveryService', value }
    },
    extractIDFromLink (deliveryService) {
      const link = deliveryService._links.self.href
      const index = link.lastIndexOf('/')
      return link.slice(index + 1)
    },
    handleMarkerSelect (point) {
      this.currentPoint = point
      const coords = this.createGeo(point.geo)
      this.marker = coords
      this.center = coords

      setTimeout(() => {
        if (!this.$refs.map) {
          return
        }

        this.openPopUp(coords)
      })
    },
    openPopUp (latLng) {
      if (this.$refs.features) {
        this.$refs.features.leafletObject.openPopup(latLng)
      }
    },
    createGeo (value = '') {
      return createGeo(value, (this.model.locality || {}).geo)
    },
    getMarkerSize (coords) {
      return this.isChoosed(coords)
          ? [34, 50]
          : [26, 40]
    },
    isChoosed (coords) {
      return !!this.marker && coords[0] === this.marker[0] && coords[1] === this.marker[1]
    },
    handleBack () {
      this.$emit('close')
    },
    handleSubmit (servicePoint) {
      const outputData = {
        ...this.model,
        servicePoint
      }

      outputData.search = undefined

      this.$emit('submit', outputData)
    },
    isSelected (service) {
      if (!service?.id) {
        return false;
      }

      // Compare the service.id with the selected servicePoint id
      return this.servicePoint?.id === service?.id || this.deliveryRequest?._embedded?.servicePoint?.id === service?.id
    }
  }
}
</script>
